(function () {
    angular.module('informaApp')
        .component('discardChangesModal', {
            templateUrl: 'components/admin-sections/whats-new-section/discard-changes-modal/template.html',
            controller: WhatsNewSection,
            bindings: {
                onSubmit: '<'
            }
        });

    function WhatsNewSection() {
    }
})();